<template>
  <div id="themes-list">
    <div>
      <div class="container space-2">
        <div class="w-md-85 w-lg-65 text-center mx-md-auto mb-5">
          <h2>{{ $t('ThemesPage.ThemeSection.Title') }}</h2>
        </div>
        <div class="row">
          <div v-for="(item, index) in List" :key="index" class="col-sm-6 col-lg-4 mb-7 mb-sm-5">
            <!-- Card -->
            <a :id="`${item.id}`" data-gtm-att="ga-academy-video-card" class="card h-100 transition-3d-hover video-btn" href="javascript:;" data-toggle="modal" :data-target="`#${item.modal}`">
              <img class="card-img-top border-bottom" :src="require('@/assets/img/portal-generator-showcases/' + `${ $t(item.image) }` + '.jpg')">
              <div class="card-body text-center">
                <h4 class="mb-0">{{ $t(item.title) }}</h4>
              </div>
            </a>
            <!-- Modal -->
            <div :id="`${item.modal}`" :key="index" class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="`${item.modal}Title`" aria-hidden="true">
              <div class="modal-xl modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 :id="`${item.modal}Title`" class="modal-title" />
                    <button type="button" class="btn btn-xs btn-icon btn-soft-secondary" data-dismiss="modal" aria-label="Close">
                      <svg aria-hidden="true" width="10" height="10" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                      </svg>
                    </button>
                  </div>
                  <div class="modal-body p-3 px-sm-8 py-0">
                    <div class="row justify-content-lg-between align-items-lg-center">
                      <div class="col-lg-5 mb-5 mb-lg-0">
                        <div class="mb-5">
                          <h2>{{ $t(item.title) }}</h2>
                          <p>{{ $t(item.description) }}</p>
                        </div>
                        <!-- <div class="mb-5">
                          <span class="d-block small text-cap font-weight-bold">Great For</span>
                          <p>{{ $t(item.recommended) }}</p>
                        </div> -->
                        <div>
                          <a v-if="item.button_build" class="btn btn-primary btn-wide mr-2 mr-sm-3 mb-2" href="https://hub.openapihub.com/login" target="_blank">{{ $t(item.button_build) }}</a>
                          <button v-if="item.button_coming" class="btn btn-primary btn-wide mr-2 mr-sm-3 mb-2" type="button" disabled style="cursor: default;">
                            {{ $t(item.button_coming) }}
                          </button>
                          <a v-if="item.button_demo" class="btn btn-outline-primary btn-white btn-wide mr-2 mr-sm-0 mb-2" :href="$t(item.demo_link)" target="_blank">{{ $t(item.button_demo) }}</a>
                        </div>
                      </div>
                      <div class="col-lg-7 ml-auto">
                        <div class="w-90 mx-auto">
                          <img class="img-fluid shadow" :src="require('@/assets/img/portal-generator-showcases/' + `${ $t(item.image) }` + '.jpg')">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6 col-lg-4 mb-4 mb-md-5">
            <div class="card h-100">
              <img class="card-img-top" src="@/assets/img/images/oah-demo-video-thumbnail.jpg">
              <div class="card-body">
                <span class="small badge badge-soft-secondary">{{ $t('ThemesPage.ComingSoon') }}</span>
                <h4 class="mt-2">{{ $t('ThemesPage.ThemeSection.MonetizeSubscription.Title') }}</h4>
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-6 col-lg-4 mb-4 mb-md-5">
            <div class="card h-100 text-center">
              <div class="my-auto py-4 px-7">
                <div class="max-w-19rem mx-auto mb-2">
                  <img class="img-fluid" src="@/assets/svg/illustrations/replay-video.svg">
                </div>
                <p>More courses are coming soon. Stay Tuned!</p>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import 'bootstrap'
import Themes from './list.json'

export default {
  name: 'ThemesList',
  data() {
    return {
      List: Themes.Themes
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-header {
  border-bottom: 0;
}
</style>
