<template>
  <div>
    <Hero />
    <ThemesList />
    <CTA />
  </div>
</template>

<script>
import Hero from './components/hero'
import ThemesList from './components/themes-list'
import CTA from './components/cta'

export default {
  name: 'Themes',
  components: {
    Hero,
    ThemesList,
    CTA
  },
  metaInfo() {
    return {
      title: 'OpenAPIHub',
      titleTemplate: 'API Portal Themes | %s',
      link: [
        { rel: 'canonical', href: 'https://www.fabrixapi.com/themes' }
      ],
      meta: [
        { name: 'description', content: 'Find the perfect theme for your API Portal. Choose from the latest designs with a wide variety of features and customization options.' },
        { property: 'og:site_name', content: 'OpenAPIHub' },
        { property: 'og:title', content: 'API Portal Themes | OpenAPIHub' },
        { property: 'og:description', content: 'Find the perfect theme for your API Portal. Choose from the latest designs with a wide variety of features and customization options.' },
        { property: 'og:image', content: 'https://www.openapihub.com/assets/img/thumbnails/openapihub-2.jpg' },
        { property: 'og:url', content: 'https://www.openapihub.com/themes' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
